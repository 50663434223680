import ActivateButton from '~/pages/Subscription/components/ActivateButton';
import { Paragraph } from '@mediahuis/chameleon-react-legacy';
import { ts } from '~/services';

const base = {
  NotAvailableModule: () => (
    <Paragraph mb={5}>
      {ts.t('Holiday.NotAvailableModule', { richContent: true })}
    </Paragraph>
  ),
  NotAvailableWKSubscription: () => (
    <Paragraph mb={5}>
      {ts.t('Holiday.NotAvailableWKSubscription', { richContent: true })}
    </Paragraph>
  ),
  NotAvailableWaiting: () => (
    <Paragraph mb={5}>{ts.t('Holiday.NotAvailableWaiting')}</Paragraph>
  ),
  NotAvailableSuspended: () => (
    <Paragraph mb={5}>{ts.t('Holiday.NotAvailableSuspended')}</Paragraph>
  ),
  NotAvailableTemporarySuspended: () => (
    <Paragraph mb={5}>
      {ts.t('Holiday.NotAvailableTemporarySuspended')}
    </Paragraph>
  ),
  NotAvailableDigi: () => (
    <Paragraph>
      {ts.t('Holiday.NotAvailableDigi', { richContent: true })}
    </Paragraph>
  ),
  NotAvailableSnel: () => (
    <Paragraph>
      {ts.t('Holiday.NotAvailableSnel', { richContent: true })}
    </Paragraph>
  ),
  NoActiveSubscription: () => {
    return (
      <>
        <Paragraph mb={6}>
          {ts.t('Holiday.NoActiveSubscription', {
            richContent: true,
          })}
        </Paragraph>
        <ActivateButton />
      </>
    );
  },
  NotAvailableActive: () => (
    <Paragraph>{ts.t('Holiday.NotAvailableActive')}</Paragraph>
  ),
  discontinueDigital: {
    optionTitle: ts.t('Holiday.DiscontinueDigital.OptionTitle'),
    optionBody: ts.t('Holiday.DiscontinueDigital.OptionBody'),
    confirmationTitle: ts.t('Holiday.DiscontinueDigital.ConfirmationTitle'),
    confirmationBody: ts.t('Holiday.DiscontinueDigital.ConfirmationBody'),
  },
  continueDigital: {
    optionTitle: ts.t('Holiday.ContinueDigital.OptionTitle'),
    optionBody: ts.t('Holiday.ContinueDigital.OptionBody'),
    confirmationTitle: ts.t('Holiday.ContinueDigital.ConfirmationTitle'),
    confirmationBody: ts.t('Holiday.ContinueDigital.ConfirmationBody'),
  },
  discontinueDigitalPrintOnly: {
    optionTitle: ts.t('Holiday.DiscontinueDigital.OptionTitlePrintOnly'),
    optionBody: ts.t('Holiday.DiscontinueDigital.OptionBody'),
    confirmationTitle: ts.t('Holiday.DiscontinueDigital.ConfirmationTitle'),
    confirmationBody: ts.t('Holiday.DiscontinueDigital.ConfirmationBody'),
  },
  continueDigitalPrintOnly: {
    optionTitle: ts.t('Holiday.ContinueDigital.OptionTitlePrintOnly'),
    optionBody: ts.t('Holiday.ContinueDigital.OptionBody'),
    confirmationTitle: ts.t('Holiday.ContinueDigital.ConfirmationTitle'),
    confirmationBody: ts.t('Holiday.ContinueDigital.ConfirmationBody'),
  },
  discontinuePrint: {
    optionTitle: ts.t('Holiday.DiscontinuePrint.OptionTitle'),
    optionBody: ts.t('Holiday.DiscontinuePrint.OptionBody'),
    confirmationTitle: ts.t('Holiday.DiscontinuePrint.ConfirmationTitle'),
    confirmationBody: ts.t('Holiday.DiscontinuePrint.ConfirmationBody'),
  },
  familyAddress: {
    optionTitle: ts.t('Holiday.FamilyAddress.OptionTitle'),
    optionBody: ts.t('Holiday.FamilyAddress.OptionBody'),
    confirmationTitle: ts.t('Holiday.FamilyAddress.ConfirmationTitle'),
    confirmationBody: ts.t('Holiday.FamilyAddress.ConfirmationBody'),
  },
  holidayAddress: {
    optionTitle: ts.t('Holiday.HolidayAddress.OptionTitle'),
    optionBody: ts.t('Holiday.HolidayAddress.OptionBody'),
    confirmationTitle: ts.t('Holiday.HolidayAddress.ConfirmationTitle'),
    confirmationBody: ts.t('Holiday.HolidayAddress.ConfirmationBody'),
  },
  charity: {
    optionTitle: ts.t('Holiday.Charity.OptionTitle'),
    optionBody: ts.t('Holiday.Charity.OptionBody'),
    confirmationTitle: ts.t('Holiday.Charity.ConfirmationTitle'),
    confirmationBody: ts.t('Holiday.Charity.ConfirmationBody'),
  },
  messages: {
    noVacationHistory: ts.t('Holiday.Messages.NoVacationHistory'),
    noVacationPlanned: ts.t('Holiday.Messages.NoVacationPlanned'),
    noVacationError: ts.t('Holiday.Messages.NoVacationError'),
  },
  pastHolidaysHeading: ts.t('Holiday.PastHolidaysHeading'),
  activeHolidaysHeading: ts.t('Holiday.ActiveHolidaysHeading'),
  plannedHolidaysHeading: ts.t('Holiday.PlannedHolidaysHeading'),
  newHolidayArrangement: ts.t('Holiday.NewHolidayArrangement'),
};

export default base;
