import axios from 'axios';
import defaultError from '~/utils/defaultError';
import { DEEPLINK_KEY } from '~/constants';
import { authService, config, ts } from '~/services';
import dayjs from 'dayjs';

const getAhApiHeaders = async () => {
  const deeplinkParams = JSON.parse(sessionStorage.getItem(DEEPLINK_KEY)) || {};
  const internalCaseId = deeplinkParams['internal-case-id'];
  const subscriptionId = deeplinkParams['subscription-id'];
  const lang = ts.getLanguage();
  let token;

  const isTokenExpired = !!dayjs(token?.expiryDate).isBefore(dayjs());
  if (!config.isAportal && (isTokenExpired || !token)) {
    const res = await authService.login();
    token = res;
  }
  return {
    'Accept-Language': `${lang}, ${lang.substring(0, 2)}, en`,
    ...(config.isAportal
      ? {
          'MH-D365-External-Case-Type': deeplinkParams['external-case-type'],
          'MH-D365-Customer-Id': deeplinkParams['customer-id'],
          'MH-D365-Agent-Username': deeplinkParams['agent-username'],
          'MH-D365-Allow-Page-Navigation':
            deeplinkParams['allow-page-navigation'],
          'MH-D365-Entity': deeplinkParams.entity,
          'MH-D365-Timestamp': deeplinkParams.timestamp,
          'MH-D365-Access-Token': deeplinkParams['access-token'],
          'MH-D365-Security-Hash': deeplinkParams['security-hash'],
          ...(internalCaseId && {
            'MH-D365-Internal-Case-Id': internalCaseId,
          }),
          ...(subscriptionId && {
            'MH-D365-Subscription-Id': subscriptionId,
          }),
        }
      : {
          Authorization: `Bearer ${token.accessToken}`,
        }),
  };
};

const ahApiCall = async (url, { headers, ...apiConfig } = {}) => {
  try {
    const res = await axios({
      url,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        ...(await getAhApiHeaders()),
      },
      ...apiConfig,
    });
    return res.data;
  } catch (err) {
    const { data, status } = err.response || {};
    const error = {
      status: data?.status || status,
      headers: err?.response?.headers || {},
      message: data?.title || defaultError,
      detail: data?.detail,
    };
    throw error;
  }
};

export default ahApiCall;
