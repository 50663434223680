import { config } from '~/services';
import { apiCall } from '~/utils';

const validateAddress = async ({ address }) => {
  const { street, postalCode, city, houseNumber, countryCode } = address;
  const res = await apiCall(
    `${config.addressServiceApiUrl}/Address/Validate?api-version=1.0`,
    {
      method: 'POST',
      data: JSON.stringify({
        addressToValidate: {
          street: {
            name: street,
          },
          city: {
            postalCode,
            name: city,
          },
          houseNumber,
          countryISOCode: countryCode,
        },
      }),
    },
  );
  return res;
};

export default validateAddress;
